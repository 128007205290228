interface AnchorOffset {
  init: () => void;
  scrollUp: () => boolean;
}

export const AnchorOffset: AnchorOffset = {
  init() {
    document.addEventListener("DOMContentLoaded", (event) => {
      /* Check if browser supports onhashchange */
      if ("onhashchange" in window) {
        /* Trigger hashchange event upon page load */
        window.addEventListener("load", () => {
          dispatchEvent(new HashChangeEvent("hashchange"));
        });

        /* Scroll page up on hashchange event */
        window.addEventListener("hashchange", () => {
          if (window.location.hash != "") {
            /* Get height of header */
            const menuHeight = (document.getElementById("header") as HTMLElement).clientHeight - 16;
            window.scrollBy(0, -menuHeight);
          }
        });
      }
    });
  },

  scrollUp() {
    return true;
  }
}
