import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '@store/store';
import pageLoader from './pageLoader';
import { StartPageSlider as initStartPageSlider } from '@layouts/utils/startPageSlider';
import { InPageNav as initInPageNav } from '@layouts/utils/inPageNav';
import { hasChilds, manipulateNodes } from '@helpers/jqueryHelpers';
import { ScrollProvider, setScrollbarWidthForCSS } from '../contexts/ScrollContext';
import { WinSizeProvider } from '@contexts/WinSizeContext';
import dataLayer from '@helpers/dataLayer';
import '../style/index.scss';

declare global {
  interface Window {
    hideHeader?: boolean;
    hideFooter?: boolean;
    state?: any;
  }
}

// Load custom modules for accordion and hero banner
require('@layouts/utils/module.ts');

window.addEventListener('load', () => {
  // Added to style manual related items on the industry page
  manipulateNodes('.l-section-25', (node) => {
    if (hasChilds(node, '.l-products-list-item-new')) {
      node.classList.add('items-padding');
    }
  });
});

window.addEventListener('popstate', function (event) {
  window.location.reload();
});

const Header = React.lazy(
  () =>
    import(
      /* webpackChunkName: "header" */
      /* webpackPreload: true */
      '@layouts/Header'
    )
);
const Footer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "footer" */
      '@layouts/Footer'
    )
);
const Coolcalc = React.lazy(() => import(/* webpackChunkName: "coolCalc" */ '@modules/CoolCalc'));

const Hexpert = React.lazy(() => import(/* webpackChunkName: "hexpert" */ '@modules/Hexpert'));

const HeaderLite = () => <Header lite />;

const render = (Component, selector) => {
  const element = document.getElementById(selector);
  if (!element) return;

  const root = createRoot(element);

  root.render(
    <Suspense fallback={<div />}>
      <Provider store={store}>
        <WinSizeProvider>
          <ScrollProvider>
            <Component dataset={element.dataset} />
          </ScrollProvider>
        </WinSizeProvider>
      </Provider>
    </Suspense>
  );
};

pageLoader(window.pageType)
  .then((page) => {
    if (!window.hideHeader) render(Header, 'header');
    render(HeaderLite, 'headerlite');
    if (page && page.length) {
      page.forEach((item) => render(item.component.default, item.selector));
    }
    render(Hexpert, 'hexpert-root');
    if (!window.hideFooter) render(Footer, 'footer');
    render(Coolcalc, 'CoolCalc');
  })
  .catch((error) => {
    console.error(error);
  });

// inPageNav init
const nav = document.querySelector('.c-in-page-nav:not(.loading)');
if (nav) {
  initInPageNav();
}

if (window.pageType === 'StartPage') {
  initStartPageSlider('.c-product-section-slider');
  initStartPageSlider('.c-service-section-slider');
}

// initializing dataLayer handler
dataLayer.init();

if (window.pageType === 'MarketoPage') {
  // create a CSS variable with the scrollbar width for Marketo page.
  setScrollbarWidthForCSS();
}
