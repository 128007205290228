import { AccordionHandler } from './AccordionHandler';
import { HeroSlider } from './HeroSlider';
import { StorySlider } from './StorySlider';
import { ContactCountrySelect } from './ContactCountrySelect';
import { InfoBox } from './InfoBox';
import { InPageNavSlider } from './InPageNavSlider';
import { ProductSectionSlider } from './ProductSectionSlider';
import { DocumentSectionSlider } from './DocumentSectionSlider';
import { AnchorOffset } from './AnchorOffset';

const modules = {
  AccordionHandler,
  HeroSlider,
  StorySlider,
  ContactCountrySelect,
  InfoBox,
  InPageNavSlider,
  ProductSectionSlider,
  DocumentSectionSlider,
};

window.addEventListener('load', () => {
  for (const prop in modules) {
    modules[prop].init();
  }
});

/* Manually init AnchorOffset, otherwise it's not possible to bind the load event */
AnchorOffset.init();

export default modules;
