interface InfoBox {
  _breakPoint: number;
  _elements: Element[];
  bindEvents(): void;
  unBindEvents(): void;
  closeAll(): void;
  clickOutsideHandler(event: MouseEvent): void;
  buttonClickHandler(event: MouseEvent): void;
  init(): void;
  reset(): void;
}

export const InfoBox: InfoBox = {
  _breakPoint: 800,
  _elements: Array.from(document.querySelectorAll(".c-info-box")),

  bindEvents: function () {
    if (!this._elements || !this._elements.length) return;

    this._elements.forEach((element) => {
      // Toggle info box on mobile
      element.addEventListener("click", this.buttonClickHandler.bind(this));
    });
    document.addEventListener("click", this.clickOutsideHandler.bind(this));
    window.addEventListener("resize", this.closeAll.bind(this));
  },

  unBindEvents: function () {
    if (!this._elements || !this._elements.length) return;
    this._elements.forEach((element) => {
      element.removeEventListener("click", this.buttonClickHandler.bind(this));
    });
    document.removeEventListener("click", this.clickOutsideHandler.bind(this));
    window.removeEventListener("resize", this.closeAll.bind(this));
  },

  closeAll: function () {
    this._elements.forEach((item) => {
      item.classList.remove("is-active");
    });
  },

  clickOutsideHandler: function (event) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains("c-info-box")) {
      this.closeAll();
    }
  },

  buttonClickHandler: function (event) {
    if (window.innerWidth < this._breakPoint) {
      event.preventDefault();
      event.stopPropagation();
      const isActive = (event.target as HTMLElement).classList.contains("is-active");
      this.closeAll();
      if (!isActive) (event.target as HTMLElement).classList.add("is-active");
    }
  },

  init: function () {
    this.bindEvents();
  },

  reset: function () {
    this.unBindEvents();
    this._elements = Array.from(document.querySelectorAll(".c-info-box"));
    this.bindEvents();
  },
};