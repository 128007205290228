import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '@services/apiSlice';
import WebinarSlice from '@pages/Webinars/Webinar.slice';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';

const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    webinars: WebinarSlice,
  },
  // Turn off devtools in production
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useDispatch: DispatchFunc = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
