import Swiper from "swiper";

interface InPageNavSlider {
  swiperObj: Swiper | undefined;
  swiper: Element | null;
  buttonNext: Element | null;
  buttonPrev: Element | null;

  updateNavButtons(event?: Event): void;
  onResize(event: Event): void;
  init(): void;
}

export const InPageNavSlider: InPageNavSlider = {
  swiperObj: undefined,
  swiper: null,
  buttonNext: null,
  buttonPrev: null,

  updateNavButtons(event?: Event) {
    const isHiddenClass = "is-hidden";
    if (this.swiperObj != undefined && this.buttonPrev && this.buttonNext) {
      this.swiperObj.isBeginning
        ? this.buttonPrev.classList.add(isHiddenClass)
        : this.buttonPrev.classList.remove(isHiddenClass);
      this.swiperObj.isEnd
        ? this.buttonNext.classList.add(isHiddenClass)
        : this.buttonNext.classList.remove(isHiddenClass);
    } else if (this.swiper) {
      const prevButton = this.swiper.querySelector(".c-in-page-nav-button.is-prev");
      const nextButton = this.swiper.querySelector(".c-in-page-nav-button.is-next");
      if (prevButton) prevButton.style.display = "none";
      if (nextButton) nextButton.style.display = "none";
    }
  },

  onResize(event: Event) {
    if (this.swiperObj) {
      this.swiperObj.slideTo(0, 10, false);
      this.updateNavButtons();
      this.swiperObj.update();
    }
  },

  init() {
    this.swiper = document.querySelectorAll(".c-in-page-nav")[0];
    if (!this.swiper) return;

    if (this.swiperObj) this.swiperObj.destroy();

    this.swiperObj = new Swiper(".c-in-page-nav-swiper", {
      slidesPerView: "auto",
      loopedSlides: 5,
      on: {
        resize: this.onResize.bind(this),
        transitionEnd: this.updateNavButtons.bind(this),
      },
    });

    this.buttonNext = this.swiper.querySelector(".c-in-page-nav-button.is-next");
    this.buttonPrev = this.swiper.querySelector(".c-in-page-nav-button.is-prev");

    if (this.buttonNext && this.buttonPrev) {
      this.buttonNext.addEventListener("click", () => this.swiperObj?.slideNext());
      this.buttonPrev.addEventListener("click", () => this.swiperObj?.slidePrev());

      this.updateNavButtons();
    }
  },
};