import Swiper from "swiper";

interface HeroSlider {
  init: () => void;
  initSwiper: () => void;
  swiper: Element | null;
  swiperObj: Swiper | null;
  delay: number;
}

export const HeroSlider: HeroSlider = {
  swiper: null,
  swiperObj: null,
  delay: 4000,

  init() {
    this.initSwiper();
    if (!this.swiper) return;
  },

  initSwiper() {
    this.swiper = document.querySelectorAll(".c-slider-swiper")[0];
    if (!this.swiper) return;

    if (this.swiperObj) this.swiperObj.destroy();

    this.delay = parseInt(this.swiper.dataset.autoPlaySpeed, 10) || 4000;
    this.swiperObj = new Swiper(".c-slider-swiper", {
      loop: true,
      autoplay: {
        delay: this.delay,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".c-slider-nav",
        clickable: true,
      },
    });
  },
};