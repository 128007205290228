export const getParams = function (url: string): { [key: string]: string } {
  const params: { [key: string]: string } = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

export const getURLFileData = function (urlString: string): {
  fileName: string;
  extension: string;
} {
  try {
    // new URL() throws an error if the URL is invalid
    const url = new URL(urlString);
    const pathnameParts = url.pathname.match(/^(\/.*\/)(.*)\.([\w]{2,4})$/);

    if (!pathnameParts) {
      throw new Error('Invalid URL');
    }

    const [_match, _path, fileName, extension] = pathnameParts;

    return {
      fileName,
      extension,
    };
  } catch (e) {
    return {
      fileName: '',
      extension: '',
    };
  }
};

export const optionallySetSearchParams = (url: URL, key: string, value: string | number) => {
  if (value) {
    url.searchParams.set(key, value.toString());
  } else {
    url.searchParams.delete(key);
  }
};
