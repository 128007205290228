interface AccordionHandler {
  toggle: (ele: HTMLElement, event: Event) => void;
  init: () => void;
}

export const AccordionHandler: AccordionHandler = {
  toggle(ele: HTMLElement, event: Event) {
    ele.classList.toggle("c-accordion-tabs-is-open");
    ele.parentElement
      .getElementsByClassName("c-accordion-tabs-body")[0]
      .classList.toggle("c-accordion-tabs-is-open");
  },

  init() {
    const elements = document.getElementsByClassName(
      "c-accordion-tabs-head c-accordion-tabs-head-listener"
    );
    if (!elements || !elements.length) return;
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", (event) => this.toggle(elements[i], event));
    }
    this.toggle(elements[0]);
  }
}
