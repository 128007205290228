import Swiper from "swiper";

interface DocumentSectionSlider {
  swiperObj: Swiper | undefined;
  swiper: Element | null;
  buttonNext: Element | null;
  buttonPrev: Element | null;
  init: () => void;
  onResize: (event: Event) => void;
  updateNavButtons: (event?: Event) => void;
}

export const DocumentSectionSlider: DocumentSectionSlider = {
  swiperObj: undefined,
  swiper: null,
  buttonNext: null,
  buttonPrev: null,

  init() {
    this.updateNavButtons = this.updateNavButtons.bind(this);
    this.onResize = this.onResize.bind(this);

    this.swiper = document.querySelectorAll(".c-document-section")[0];
    if (!this.swiper) return;

    if (this.swiperObj) this.swiperObj.destroy();

    this.swiperObj = new Swiper(".c-document-section-swiper", {
      slidesPerView: "auto",
      loopedSlides: 5,
      on: {
        resize: this.onResize,
        transitionEnd: this.updateNavButtons,
      },
    });
    this.buttonNext = this.swiper.querySelector(".c-document-section-button.is-next");
    this.buttonPrev = this.swiper.querySelector(".c-document-section-button.is-prev");
    if (this.buttonNext) {
      this.buttonNext.addEventListener("click", () => this.swiperObj?.slideNext());
    }
    if (this.buttonPrev) {
      this.buttonPrev.addEventListener("click", () => this.swiperObj?.slidePrev());
    }

    this.updateNavButtons();
  },

  onResize(event: Event) {
    // this is to slide the swiper to first index on resizing
    this.swiperObj?.slideTo(0, 10, false);
    this.updateNavButtons();
    this.swiperObj?.update();
  },

  updateNavButtons(event?: Event) {
    const isHiddenClass = "is-hidden";
    if (this.swiperObj !== undefined && this.buttonPrev && this.buttonNext) {
      this.buttonPrev.classList.toggle(isHiddenClass, this.swiperObj.isBeginning);
      this.buttonNext.classList.toggle(isHiddenClass, this.swiperObj.isEnd);
    } else if (this.swiper) {
      const prevButton = this.swiper.querySelector(".c-in-page-nav-button.is-prev");
      const nextButton = this.swiper.querySelector(".c-in-page-nav-button.is-next");
      if (prevButton) {
        (prevButton as HTMLElement).style.display = "none";
      }
      if (nextButton) {
        (nextButton as HTMLElement).style.display = "none";
      }
    }
  },
};