export const InPageNav = () => {
  const nav = document.querySelector('.c-in-page-nav:not(.loading)');
  document.addEventListener('scroll', () => {
    let sticky: Element | null;
    let headerMain: Element | null;

    if (sticky === undefined) {
      sticky = document.querySelector('.l-header-sticky');
    }

    if (headerMain === undefined) {
      headerMain = document.querySelector('.l-header-main');
    }

    if (sticky && headerMain) {
      if (window.pageYOffset > 0) {
        nav.classList.add('sticky');
        sticky.classList.remove('no-shadow');
      } else {
        nav.classList.remove('sticky');
        sticky.classList.add('no-shadow');
      }
    }
  });

  const lis = document.querySelectorAll('.c-in-page-nav li a');
  for (let i = 0; i < lis.length; i++) {
    lis[i].addEventListener('click', smoothScroll);
  }
};

function smoothScroll(e: Event) {
  e.preventDefault();

  const id = (e.target as HTMLAnchorElement).hash.slice(1);
  document.getElementById(id)?.scrollIntoView({
    block: 'start',
    inline: 'nearest',
  });
}
