import Swiper from "swiper";

interface StorySlider {
  swiperObj: Swiper | null;
  swiper: Element | null;
  breakpoint: number;
  windowSize: number | null;
  init: () => void;
  onResize: (event: Event) => void;
  _initSwiper: () => void;
  _attachEventListeners: () => void;
  _setSlidersStyleBasedOnScreenSize: () => void;
}

export const StorySlider: StorySlider = {
  swiperObj: null,
  swiper: null,
  breakpoint: 480,
  windowSize: null,
  init() {
    this.breakpoint = 480;

    this.onResize = this.onResize.bind(this);

    this._initSwiper();
    this._attachEventListeners();
  },

  _initSwiper() {
    this.swiper = document.querySelectorAll(".c-story-swiper")[0];
    if (!this.swiper) return;

    const slidesCount = this.swiper.querySelectorAll(".swiper-slide").length;

    if (this.swiperObj) this.swiperObj.destroy();

    this.swiperObj = new Swiper(".c-story-swiper", {
      loop: true,
      slidesPerView: "auto",
      loopedSlides: slidesCount,
      spaceBetween: 15,
      slidesOffsetBefore: 50,
      slidesOffsetAfter: 50,
      observer: true,
      observeParents: true,

      pagination: {
        el: ".c-slider-nav",
        clickable: true,
        renderBullet: function (index: number, className: string) {
          return '<span class="' + className + '"></span>';
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is <=
        480: {
          slidesPerView: 1,
          loopedSlides: null,
          spaceBetween: 0,
          slidesOffsetBefore: 10,
          slidesOffsetAfter: 0,
        },
      },
    });
  },

  _attachEventListeners() {
    window.addEventListener("resize", this.onResize);
  },

  onResize(event: Event) {
    this.windowSize = (event.target as Window).innerWidth;
    this._setSlidersStyleBasedOnScreenSize();
  },

  _setSlidersStyleBasedOnScreenSize() {
    this.swiperObj?.update();
  },
};

StorySlider.init();