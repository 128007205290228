interface ContactCountrySelect {
  init: () => void;
  _changeLocation: (event: Event) => void;
}

export const ContactCountrySelect: ContactCountrySelect = {
  init() {
    const selects = document.querySelectorAll(
      'select[data-module="redirect-to-value"][name="Country"]'
    );
    if (!selects || !selects.length) return;

    for (let i = 0; i < selects.length; i++) {
      selects[i].addEventListener("change", (e) => this._changeLocation(e));
    }
  },

  _changeLocation(event) {
    const ele = event.target as HTMLSelectElement;
    const val = ele.value;
    const target = ele.getAttribute("data-target");
    if (val) {
      if (target === "_blank") {
        window.open(val);
      } else {
        window.location.href = val;
      }
    }
  },
};
