import { EventArgs, EventName } from './dataLayer.types';
import { getURLFileData } from './URLHelpers';

export class DataLayer {
  constructor() {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
  }

  private initDownloadEvents() {
    const downloadableItems = document.querySelectorAll<HTMLAnchorElement>(
      'a[data-datalayer-download]'
    );
    downloadableItems.forEach((item) => {
      item.addEventListener('click', () => {
        const { mimeType, linkText } = JSON.parse(item.dataset.fileMetadata);
        const { fileName } = getURLFileData(item.href);
        const href = item.getAttribute('href') || '';

        this.push('file_download', {
          file_name: fileName,
          file_extension: mimeType,
          file_category: undefined,
          file_context: undefined,
          link_text: linkText,
          link_url: href,
        });
      });
    });
  }

  init() {
    this.initDownloadEvents();
    return this;
  }

  push<T extends EventName>(name: T, args: Omit<Extract<EventArgs, { event: T }>, 'event'>) {
    const dataLayerObj = {
      event: name,
      ...args,
    };

    window.dataLayer.push(dataLayerObj);
  }
}

export default new DataLayer();
