import React, { createContext, useEffect, ReactNode, useState, useContext } from 'react';
import { throttle } from '@helpers/waitFunctions';

interface ScrollState {
  scrollHeight: number;
  scrollTop: number;
}

const getScrollPositions = () => {
  return {
    scrollHeight: window.pageYOffset + window.innerHeight,
    scrollTop: window.pageYOffset,
  };
};

export const setScrollbarWidthForCSS = () => {
  const root = document.documentElement;
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;

  root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
};

// Define the context
export const ScrollContext = createContext<ScrollState>(getScrollPositions());

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const [scroll, setScroll] = useState(getScrollPositions());

  const handleScroll = throttle(() => {
    const positions = getScrollPositions();
    setScroll(positions);
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <ScrollContext.Provider value={scroll}>{children}</ScrollContext.Provider>;
};

export const useScrollPosition = (): ScrollState => {
  return useContext(ScrollContext);
};
