import React, { createContext, useEffect, ReactNode, useState, useContext } from 'react';
import { throttle } from '@helpers/waitFunctions';

interface WinSizeState {
  innerHeight: number;
  innerWidth: number;
  isDesktop: boolean;
  isDesktopForHeader: boolean;
  isMobileForHeader: boolean; // To handle megamenu for screen size if content exceeds the width and adds horizontal scroll
  isTabletPortrait: boolean;
  isMobile: boolean;
}

const getSizes = () => {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    isDesktop: window.innerWidth >= 800,
    isDesktopForHeader: window.innerWidth > 900,
    isMobileForHeader: window.screen.width < 900,
    isTabletPortrait: window.innerWidth >= 480 && window.innerWidth <= 768,
    isMobile: window.innerWidth < 480,
  };
};

// Define the context
export const WinSizeContext = createContext<WinSizeState>(getSizes());

export const WinSizeProvider = ({ children }: { children: ReactNode }) => {
  const [winSize, setWinSize] = useState(getSizes());

  const handleSizeChange = throttle(() => {
    setWinSize(getSizes());
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  }, []);

  return <WinSizeContext.Provider value={winSize}>{children}</WinSizeContext.Provider>;
};

export const useWinSize = (): WinSizeState => {
  return useContext(WinSizeContext);
};
