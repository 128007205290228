type ThrottleOptions = {
  leading?: boolean;
  trailing?: boolean;
};

type ThrottledFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): ReturnType<T>;
  cancel: () => void;
};

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  options?: ThrottleOptions
): ThrottledFunction<T> {
  let timeout: NodeJS.Timeout | null,
    context: any,
    args: any[],
    result: ReturnType<T>;
  let previous = 0;
  if (!options) options = {};

  const later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  const throttled = function () {
    const _now = Date.now();
    if (!previous && options.leading === false) previous = _now;
    const remaining = wait - (_now - previous);
    context = this;
    args = Array.from(arguments);
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = _now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  throttled.cancel = function () {
    clearTimeout(timeout!);
    previous = 0;
    timeout = context = args = null;
  };

  return throttled;
}

type DebouncedFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): ReturnType<T>;
  cancel: () => void;
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  immediate?: boolean
): DebouncedFunction<T> {
  let timeout: NodeJS.Timeout | null,
    previous: number | null,
    args: any[],
    result: ReturnType<T>,
    context: any;

  const later = function () {
    const passed = Date.now() - (previous as number);
    if (wait > passed) {
      timeout = setTimeout(later, wait - passed);
    } else {
      timeout = null;
      if (!immediate) result = func.apply(context, args);

      // This check is needed because func can recursively invoke debounced.
      if (!timeout) args = context = null;
    }
  };

  const debounced = function () {
    context = this;
    args = Array.from(arguments);
    previous = Date.now();
    if (!timeout) {
      timeout = setTimeout(later, wait);
      if (immediate) result = func.apply(context, args);
    }
    return result;
  };

  debounced.cancel = function () {
    clearTimeout(timeout!);
    timeout = args = context = null;
  };

  return debounced;
}