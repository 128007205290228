import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface WebinarsState {
  filter: { type: string; category: string; };
}

// Define the initial state using that type
const initialState: WebinarsState = {
  filter: {
    type: 'All',
    category: 'All'
  }
};

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<string>) => {
      state.filter.category = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.filter.type = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    }
  },
});

// Export the action creators for use in components
export const { setCategory, setType, resetFilter } = webinarsSlice.actions;

export const selectWebinarsFilter = (state: { webinars: WebinarsState; }) => state.webinars.filter;

// Export the reducer for use in the Redux store
export default webinarsSlice.reducer;