import Swiper from "swiper";

interface ProductSectionSlider {
  swiperObj: Swiper | undefined;
  swiper: Element | undefined;
  breakpoint: number;
  windowSize: number | undefined;
  onResize: (event: Event) => void;
  init: () => void;
  setSlidersStyleBasedOnScreenSize: () => void;
}

export const ProductSectionSlider: ProductSectionSlider = {
  swiperObj: undefined,
  swiper: undefined,
  breakpoint: 480,
  windowSize: undefined,

  onResize(event: Event) {
    this.windowSize = (event.target as Window).innerWidth;
    this.setSlidersStyleBasedOnScreenSize();
  },

  init() {
    this.swiper = document.querySelectorAll(".c-product-section-swiper-container")[0];
    if (!this.swiper) return;
    const slidesCount = this.swiper.querySelectorAll(".c-product-section-swiper-slide").length;

    if (this.swiperObj) this.swiperObj.destroy();

    this.swiperObj = new Swiper(".c-product-section-swiper-container", {
      loop: true,
      slidesPerView: "auto",
      loopedSlides: slidesCount,
      spaceBetween: 15,
      slidesOffsetBefore: 50,
      slidesOffsetAfter: 50,
      observer: true,
      observeParents: true,

      pagination: {
        el: ".c-product-section-slider-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },
      },
      navigation: {
        nextEl: ".c-product-section-swiper-button-next",
        prevEl: ".c-product-section-swiper-button-prev",
      },
      breakpoints: {
        // when window width is <=
        480: {
          slidesPerView: 1,
          loopedSlides: null,
          spaceBetween: 10,
          slidesOffsetBefore: 10,
          slidesOffsetAfter: 0,
        },
      },
    });

    window.addEventListener("resize", this.onResize.bind(this));
  },

  setSlidersStyleBasedOnScreenSize() {
    this.swiperObj?.update();
  },
};
