import Swiper from "swiper";

interface StartPageSlider {
  (name: string): Swiper;
}

export const StartPageSlider: StartPageSlider = (name: string) => {
  const slides = document.querySelectorAll(`${name} .c-start-page-slide`);

  const mySwiper = new Swiper(name, {
    // Optional parameters
    direction: "horizontal",
    loop: slides.length > 4,
    autoHeight: true,
    slidesPerView: 4,

    // If we need pagination
    pagination: {
      el: name + "-pagination",
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: name + "-container .c-swiper-button-next",
      prevEl: name + "-container .c-swiper-button-prev",
    },

    breakpoints: {
      // when window width is <=
      900: {
        slidesPerView: 3,
        spaceBetween: 32,
      },

      700: {
        slidesPerView: 2,
        spaceBetween: 32,
      },

      480: {
        slidesPerView: 1,
        spaceBetween: 32,
      },
    },

    spaceBetween: 32,
  });

  return mySwiper;
};